export default function useMenus() {
  return [
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'creditoConsumidos',
      label: 'Créditos consumidos',
    },
    {
      key: 'atualizacao',
      label: 'Data de atualização',
    },
    {
      key: 'usuario',
      label: 'Usuário',
    },
    {
      key: 'erro',
      label: 'Status de erro',
    },
  ]
}
