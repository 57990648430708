<template>
  <div
    style="max-width: 30%"
    class="sidebar-left"
  >
    <div class="sidebar">
      <div class="sidebar-content menu-cadastro-client">
        <b-list-group
          class="list-group-messages"
          style="border-radius: 0 !important"
        >
          <div
            v-for="menu in menusCadastro"
            :key="menu.value + $route.path"
          >
            <b-list-group-item
              v-b-toggle="`${menu.value}collapse`"
              style="max-width: 200px;"
              :active="menuAtivo === menu.value"
              @click="selecionar(menu)"
            >
              <span class="align-text-bottom line-height-1">{{ menu.text }}</span>
            </b-list-group-item>
          </div>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    relatorios: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const menusCadastro = [
      { value: 'SituaacaoFiscal', text: 'Situação fiscal' },
      { value: 'declaracaoDcftWeb', text: 'DCTFWeb' },
      { value: 'caixaPostal', text: 'Caixa Postal - leitura de mensagens e-CAC' },
      { value: 'das', text: 'DAS' },
    ]
    const menuAtivo = ref(null)

    const selecionar = menu => {
      menuAtivo.value = menu.value
      context.emit('selecionado', menu.value)
    }

    const existeMenu = menu => props.relatorios.findIndex(f => f.toLowerCase() === menu.toLowerCase()) >= 0

    const primeiroMenu = menusCadastro.find(menu => existeMenu(menu.value))
    if (primeiroMenu) {
      selecionar(primeiroMenu)
    }

    return {
      menuAtivo,
      selecionar,
      existeMenu,
      menusCadastro,
    }
  },
}
</script>
