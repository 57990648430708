<template>
  <b-modal
    id="relatorio-consumo"
    :ref="'relatorio-consumo'"
    :title="'Histórico de atualizações manuais via Serpro - ' + empresa.razaoSocial"
    size="lg"
    no-close-on-backdrop
    no-stacking
    @hidden="fecharModal"
  >
    <template v-slot:modal-footer>
      <div class="w-100">
        <div>
          <p
            class="float-left"
            style="max-width: 90%;"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="16"
              :class="'text-warning'"
            />
            Estamos apresentando um resumo da consulta.
          </p>
        </div>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="fecharModal"
        >
          OK
        </b-button>
      </div>
    </template>
    <menu-lateral
      :relatorios="['SituaacaoFiscal', 'DeclaracaoDcftWeb', 'CaixaPostal', 'Das']"
      @selecionado="selecionarRelatorio"
    />
    <visualizacao
      :relatorios="relatorioSelecionada"
      :grupo="tipoRelatoriosSelecionada"
    />
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import MenuLateral from './components/menu-lateral/MenuLateral.vue'
import Visualizacao from './components/visualizacao/Visualizacao.vue'
import api from '../../services/api'

export default {
  name: 'Mensagem',
  components: {
    MenuLateral,
    Visualizacao,
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
    filtro: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const relatorios = ref([])
    const relatorioSelecionada = ref([])
    const tipoRelatoriosSelecionada = ref([])

    const fecharModal = () => {
      context.emit('hidden')
    }

    const selecionarRelatorio = relatorio => {
      tipoRelatoriosSelecionada.value = relatorio
      relatorioSelecionada.value = relatorios.value.filter(find => find.grupoRelatorio.toLowerCase() === relatorio.toLowerCase())
      return relatorioSelecionada.value
    }

    api.getRelatorioConsumo(props.empresa.id, props.filtro)
      .then(payload => {
        if (payload.data) {
          relatorios.value = payload.data
          const grupo = relatorios.value[0].grupoRelatorio
          selecionarRelatorio(grupo)
          context.root.$bvModal.show('relatorio-consumo')
        }
      })

    return {
      fecharModal,
      selecionarRelatorio,
      tipoRelatoriosSelecionada,
      relatorioSelecionada,
    }
  },
}
</script>
