<template>
  <b-row>
    <b-col>
      <tabela-repeticao
        v-model="relatorios"
        :somente-visualizacao="true"
        :colunas="colunas"
        class="tabela-rotinas"
      >
        <template #visualizacao="{ slotScope: { valor } }">
          <div class="ml-50">
            {{ valor }}
          </div>
        </template>
      </tabela-repeticao>
    </b-col>
  </b-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useMenus from './useMenus'

export default {
  name: 'VisualizacaoPendencias',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    relatorios: {
      type: Array,
      required: true,
    },
    grupo: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const colunas = ref([])

    watch(() => props.grupo, () => {
      colunas.value = useMenus()
    })

    return {
      colunas,
    }
  },
}
</script>
